<template>
	<div class="notice_popup human int">
		<div class="close">
			<img src="/images/mem/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<div class="tit" v-if="div == 'pass'">기업정보 확인!</div>
		<div class="tit" v-else>중복가입 불가!</div>
		
		<div class="member" v-if="div == 'pass'" style="line-height: 35px;">동일한 기업의 가입정보가 있습니다.<br/>입력하신 기업의 정보로 회원가입 하시겠습니까?</div>
		<div class="member" v-else style="line-height: 35px;">동일한 기업 정보로 가입한 회원이 있습니다!<br/>해당 기업 정보로 가입이 불가합니다!</div>
		
		<div class="select-btn">
			<div class="btn" @click="$emit('close')" v-if="div == 'pass'">취소</div>
			<div class="btn" @click="$emit('close', true)" v-if="div == 'pass'">예</div>
			<div class="btn" @click="$emit('close')" v-else style="background-color: #75A5BF;">닫기</div>
		</div>
	</div>
</template>

<script>
export default {
	props:['param'],
	data() {
		return {
			div : this.param,
		}
	}
}
</script>